import { Keys } from "./Keys";
import { Pixel } from "@/logic/Shared/Pixel";
import { ImageUtils } from "@/logic/Shared/ImageUtils";

export class GameModel {
	private gameChangedListeners: GameChangedEvent[];

	private xPos!: number;
	private yPos!: number;
	private level!: number;
	private pixels: Pixel[][];

	private readonly debugMode = false;

	public readonly boardSizeX = 100;
	public readonly boardSizeY = 50;
	private readonly playerMovementSpeed = 1;

	private readonly playerIndicatorPixelSize = .2;
	private readonly playerIndicatorPixelLocation = .7;
	//private readonly playerIndicatorPixelSizeX = .2;
	//private readonly playerIndicatorPixelSizeY = .2;
	//private readonly playerIndicatorPixelLocationX = .7;
	//private readonly playerIndicatorPixelLocationY = .7;

	public constructor() {
		this.pixels = [];
		for (let i = 0; i < this.boardSizeX; i++) {
			this.pixels.push([]);
			for (let j = 0; j < this.boardSizeY; j++) {
				this.pixels[i].push(new Pixel(0, 0, 0));
			}
		}

		this.gameChangedListeners = [];
		this.resetGame();
	}

	public addListener(gameChangedEvent: GameChangedEvent): void {
		this.gameChangedListeners.push(gameChangedEvent);
	}

	public respondToInput(evt: KeyboardEvent): void {
		if ((evt.keyCode === Keys.W) || (evt.keyCode === Keys.w) || (evt.keyCode === Keys.UP)) {
			this.moveY(this.playerMovementSpeed);
		} else if ((evt.keyCode === Keys.S) || (evt.keyCode === Keys.s) || (evt.keyCode === Keys.DOWN)) {
			this.moveY(-this.playerMovementSpeed);
		} else if ((evt.keyCode === Keys.A) || (evt.keyCode === Keys.a) || (evt.keyCode === Keys.LEFT)) {
			this.moveX(-this.playerMovementSpeed);
		} else if ((evt.keyCode === Keys.D) || (evt.keyCode === Keys.d) || (evt.keyCode === Keys.RIGHT)) {
			this.moveX(this.playerMovementSpeed);
		} else {
			return;
		}
		this.updatePixelsAndNotifyChange();
	}

	public getGamePixels(): Pixel[][] {
		return this.pixels;
	}

	private resetGame(): void {
		this.level = 1;
		this.startLevel();
	}

	private startLevel(): void {
		this.setX(0);
		this.setY(0);
		this.updatePixelsAndNotifyChange();
	}

	private moveX(amount: number) {
		this.setX(this.xPos + amount);
	}

	private moveY(amount: number) {
		this.setY(this.yPos + amount);
	}

	private setX(x: number) {
		this.xPos = this.fixBounds(x, this.boardSizeX - 1);
	}

	private setY(y: number) {
		this.yPos = this.fixBounds(y, this.boardSizeY - 1);
	}

	private fixBounds(pos: number, max: number): number {
		return (pos < 0) ? 0 : (pos > max) ? max : pos;
	}

	private updatePixelsAndNotifyChange(): void {
		for (let i = 0; i < this.boardSizeX; i++) {
			for (let j = 0; j < this.boardSizeY; j++) {
				this.pixels[i][j] = new Pixel(0, 0, 0);
			}
		}

		if (this.debugMode) {
			ImageUtils.copyPixelsToPixels([[new Pixel(255, 0, 0)]], this.pixels, this.xPos, this.boardSizeY - 1 - this.yPos);
		} else {
			const playerPixels = this.getPlayerIndicatorPixels();
			ImageUtils.copyPixelsToPixels(playerPixels, this.pixels, this.getPlayerIndicatorStartX(), this.getPlayerIndicatorStartY());
		}

		for (const listener of this.gameChangedListeners) {
			listener(this);
		}
	}

	private getPlayerIndicatorPixels(): Pixel[][] {
		const pixel = new Pixel(this.posToByte(this.xPos, this.boardSizeX), this.posToByte(this.yPos, this.boardSizeY), 0);

		const playerPixels: Pixel[][] = [];
		for (let i = 0; i < this.getPlayerIndicatorSizeX(); i++) {
			playerPixels.push([]);
			for (let j = 0; j < this.getPlayerIndicatorSizeY(); j++) {
				playerPixels[i].push(pixel);
			}
		}

		return playerPixels;
	}

	private getPlayerIndicatorStartX() {
		//return this.posPercToAbs(this.playerIndicatorPixelLocationX, this.boardSizeX);
		return this.boardSizeX - this.getPlayerStartDistFromEnd();
	}

	private getPlayerIndicatorStartY() {
		//return this.posPercToAbs(this.playerIndicatorPixelLocationY, this.boardSizeY);
		return this.boardSizeY - this.getPlayerStartDistFromEnd();
	}

	private getPlayerIndicatorSizeX() {
		//return this.posPercToAbs(this.playerIndicatorPixelLocationX + this.playerIndicatorPixelSizeX, this.boardSizeX) - this.getPlayerIndicatorStartX() + 1;
		return this.getPlayerIndicatorSize();
	}

	private getPlayerIndicatorSizeY() {
		//return this.posPercToAbs(this.playerIndicatorPixelLocationY + this.playerIndicatorPixelSizeY, this.boardSizeY) - this.getPlayerIndicatorStartY() + 1;
		return this.getPlayerIndicatorSize();
	}

	private getPlayerIndicatorSize() {
		return this.getPlayerStartDistFromEnd() - this.getPlayerEndDistFromEnd() + 1;
	}

	private getPlayerStartDistFromEnd() {
		const minBoardSize = Math.min(this.boardSizeX, this.boardSizeY);
		const loc = this.posPercToAbs(this.playerIndicatorPixelLocation, minBoardSize);
		return minBoardSize - loc;
	}

	private getPlayerEndDistFromEnd() {
		const minBoardSize = Math.min(this.boardSizeX, this.boardSizeY);
		const loc = this.posPercToAbs(this.playerIndicatorPixelLocation + this.playerIndicatorPixelSize, minBoardSize);
		return minBoardSize - loc;
	}

	private posPercToAbs(posPerc: number, boardSize: number) {
		return Math.round((posPerc * boardSize) - 1);
	}
	
	private posToByte(pos: number, boardSize: number) {
		return Math.round(((256 - 1) / (boardSize - 1)) * pos);
	}
}

type GameChangedEvent = (game: GameModel) => void;