export class Pixel {
	public red: number;
	public green: number;
	public blue: number;
	public alpha: number;

	public static readonly MAXDIFF = 255 * 255 * 3;

	public constructor(red: number, green: number, blue: number, alpha = 255) {
		this.red = red;
		this.green = green;
		this.blue = blue;
		this.alpha = alpha;
	}

	public diff(other: Pixel): number {
		return ((this.red - other.red) * (this.red - other.red)) +
			((this.green - other.green) * (this.green - other.green)) +
			((this.blue - other.blue) * (this.blue - other.blue));
	}

	public toString = (): string => {
		return "red: " + this.red + ", green: " + this.green + ", blue: " + this.blue + ", alpha: " + this.alpha;
	}
}