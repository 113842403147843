

import { Vue } from 'vue-class-component';
import { GameModel } from "@/logic/TheMostDifficultGame/GameModel";
import { GameDrawer } from "@/logic/TheMostDifficultGame/GameDrawer";

export default class TheMostDifficultGame extends Vue {
    gameModel!: GameModel;
    gameDrawer!: GameDrawer;

    mounted() {
        this.gameModel = new GameModel();
        this.gameDrawer = new GameDrawer(this.gameModel, this.$refs.gameCanvas as HTMLCanvasElement);
        window.addEventListener("keydown", this.doKeyDown, true);
    }

    doKeyDown(evt: KeyboardEvent) {
        this.gameModel.respondToInput(evt);
    }
}

