import { GameModel } from "./GameModel";
import { ImageUtils } from "@/logic/Shared/ImageUtils";

export class GameDrawer {
	private gameModel: GameModel;
	private canvas: HTMLCanvasElement;

	// This is necessary because you can't transform image data, you can only do it with images, so we have to put the image data
	// onto a temp canvas first, then draw the temp canvas onto the real canvas, using transforms that will now be respected:
	private tempCanvas: HTMLCanvasElement;

	public constructor(gameModel: GameModel, gameCanvas: HTMLCanvasElement) {
		this.tempCanvas = document.createElement('canvas');
		this.tempCanvas.width = gameModel.boardSizeX;
		this.tempCanvas.height = gameModel.boardSizeY;

		this.gameModel = gameModel;
		this.canvas = gameCanvas;
		this.gameModel.addListener(this.gameChanged);
		this.resizeCanvas();

		const outer = this;
		window.addEventListener("resize", function () { outer.resizeCanvas() }, false);
	}

	private resizeCanvas() {
		const width = this.canvas.clientWidth;
		const height = this.canvas.clientHeight;

		if ((this.canvas.width !== height) || (this.canvas.height !== height)) {
			this.canvas.width = width;
			this.canvas.height = height;
		}

		this.updateGame();
	}

	private gameChanged = (_gameModel: GameModel): void => {
		this.updateGame();
	}

	private updateGame() {
		const context = this.canvas.getContext("2d");
		this.wipeCanvas(context!);
		this.drawGameBoard(context!);
	}

	private wipeCanvas(context: CanvasRenderingContext2D): void {
		context.fillStyle = "white";
		context.fillRect(0, 0, this.canvas.width, this.canvas.height);
	}

	private drawGameBoard(context: CanvasRenderingContext2D): void {
		const imageData = context.createImageData(this.gameModel.boardSizeX, this.gameModel.boardSizeY);
		const pixels = this.gameModel.getGamePixels();

		ImageUtils.copyPixelsToImageData(pixels, imageData);

		const tempContext = this.tempCanvas.getContext("2d");
		tempContext!.putImageData(imageData, 0, 0);

		// This is technically not right, because you are allowing for game boards that are different aspect ratios:
		const scaleAmount = Math.min(this.canvas.width / this.gameModel.boardSizeX, this.canvas.height / this.gameModel.boardSizeY);
		context.resetTransform();
		context.transform(scaleAmount, 0, 0, scaleAmount, 0, 0);
		context.imageSmoothingEnabled = false;
		context.drawImage(this.tempCanvas, 0, 0);
	}
}