import { Pixel } from "./Pixel";

export class ImageUtils {
	/**
	 * Gets the ImageData for an image, optionally at a different size than the original.
	 */
	public static convertImageToImageData(image: HTMLImageElement, maxWidth = 0, maxHeight = 0): ImageData {
		const [newWidth, newHeight] = this.shrinkSize(image.width, image.height, maxWidth, maxHeight);
		const tempCanvas = document.createElement('canvas');
		const tempContext = tempCanvas.getContext('2d');
		tempCanvas.width = newWidth;
		tempCanvas.height = newHeight;
		tempContext!.drawImage(image, 0, 0, newWidth, newHeight);
		return tempContext!.getImageData(0, 0, newWidth, newHeight);
	}

	public static getAverageColor(imageData: ImageData): Pixel {
		let totalRed = 0;
		let totalGreen = 0;
		let totalBlue = 0;
		let totalAlpha = 0; // Doesn't really make sense but ... meh whatever, it should always be 255 anyway
		const numPixels = imageData.height * imageData.width;

		for (let i = 0; i < numPixels; i++) {
			totalRed += imageData.data[i * 4];
			totalGreen += imageData.data[i * 4 + 1];
			totalBlue += imageData.data[i * 4 + 2];
			totalAlpha += imageData.data[i * 4 + 3];
		}

		return new Pixel(
			Math.round(totalRed / numPixels),
			Math.round(totalGreen / numPixels),
			Math.round(totalBlue / numPixels),
			Math.round(totalAlpha / numPixels));
	}

	public static convertImageDataToImageSrc(imageData: ImageData): string {
		const tempCanvas = document.createElement('canvas');
		const tempContext = tempCanvas.getContext('2d');
		tempCanvas.width = imageData.width;
		tempCanvas.height = imageData.height;
		tempContext!.putImageData(imageData, 0, 0);
		return tempCanvas.toDataURL();
	}

	public static copyPixelsToImageData(copyFrom: Pixel[][], copyTo: ImageData, x = 0, y = 0) {
		for (let i = 0; i < copyFrom.length; i++) {
			for (let j = 0; j < copyFrom[i].length; j++) {
				this.setImageDataPixelValue(copyTo, x + i, y + j, copyFrom[i][j]);
			}
		}
	}

	public static copyPixelsToPixels(copyFrom: Pixel[][], copyTo: Pixel[][], x = 0, y = 0) {
		for (let i = 0; i < copyFrom.length; i++) {
			for (let j = 0; j < copyFrom[i].length; j++) {
				copyTo[x + i][y + j] = copyFrom[i][j];
			}
		}
	}

	public static copyImageDataToImageData(copyFrom: ImageData, copyTo: ImageData, x = 0, y = 0): void {
		for (let i = 0; i < copyFrom.width; i++) {
			for (let j = 0; j < copyFrom.height; j++) {
				const copyToIndex = ImageUtils.pointToIndex(x + i, y + j, copyTo);
				const copyFromIndex = ImageUtils.pointToIndex(i, j, copyFrom);
				copyTo.data[copyToIndex] = copyFrom.data[copyFromIndex];
				copyTo.data[copyToIndex + 1] = copyFrom.data[copyFromIndex + 1];
				copyTo.data[copyToIndex + 2] = copyFrom.data[copyFromIndex + 2];
				copyTo.data[copyToIndex + 3] = copyFrom.data[copyFromIndex + 3];
			}
		}
	}

	public static getImageDataPixelValue(imageData: ImageData, x: number, y: number): Pixel {
		const index = ImageUtils.pointToIndex(x, y, imageData);
		return new Pixel(imageData.data[index], imageData.data[index + 1], imageData.data[index + 2], imageData.data[index + 3]);
	}

	public static setImageDataPixelValue(imageData: ImageData, x: number, y: number, pixel: Pixel) {
		const index = ImageUtils.pointToIndex(x, y, imageData);
		imageData.data[index] = pixel.red;
		imageData.data[index + 1] = pixel.green;
		imageData.data[index + 2] = pixel.blue;
		imageData.data[index + 3] = pixel.alpha;
	}

	// TODO: Maybe this isn't needed since what you actually use is the image data?
	public static async resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number) {
		const [newWidth, newHeight] = this.shrinkSize(image.width, image.height, maxWidth, maxHeight);

		const canvas = document.createElement('canvas');
		canvas.width = newWidth;
		canvas.height = newHeight;

		const context = canvas.getContext('2d')!;
		context.drawImage(image, 0, 0, newWidth, newHeight);

		const newImage = document.createElement("img");
		newImage.src = canvas.toDataURL();

		await newImage.decode();
		return newImage;
	}

	private static pointToIndex(x: number, y: number, imageData: ImageData): number {
		return (y * imageData.width + x) * 4;
	}

	private static shrinkSize(width: number, height: number, maxWidth: number, maxHeight: number): [number, number] {
		maxWidth = maxWidth === 0 ? width : maxWidth;
		maxHeight = maxHeight === 0 ? height : maxHeight;

		let scale = Math.min((maxWidth / width), (maxHeight / height));
		scale = scale > 1 ? 1 : scale; // only shrink don't grow
		return [width * scale, height * scale];
	}
}