export class Keys {
	public static readonly W = 87;
	public static readonly w = 119;
	public static readonly A = 65;
	public static readonly a = 97;
	public static readonly S = 83;
	public static readonly s = 115;
	public static readonly D = 68;
	public static readonly d = 100;
	public static readonly UP = 38;
	public static readonly DOWN = 40;
	public static readonly LEFT = 37;
	public static readonly RIGHT = 39;
}